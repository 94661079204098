@import url("https://fonts.googleapis.com/css2?family=Exo+2:wght@500&family=Poppins:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Protest+Strike&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Crimson+Text&family=Kanit:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Protest+Strike&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Poppins:wght@300;400;600;700&family=Roboto+Condensed:ital@0;1&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  overflow-x: hidden;
}
:root {
  --primary-backgroundcolor: #16020a;
  --secondary-backgroundcolor: #d39c60;
}
.text-end {
  text-align: end;
}
body {
  overflow-x: hidden !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto Condensed", sans-serif !important;
  font-weight: 700 !important;
}
a,
p {
  font-family: "Poppins", sans-serif !important;
  font-weight: 400 !important;
  font-style: normal !important;
}
section {
  margin: 50px 0 !important;
}
.text-center {
  text-align: center;
}
ul {
  font-family: "Poppins", sans-serif !important;
}

.goog-te-gadget-simple{
background: #3F9483 !important;
border-radius: 8px !important;
  padding:5px 15px  !important;
  color: var(--secondary-backgroundcolor) !important;
}
.goog-te-gadget-simple .VIpgJd-ZVi9od-xl07Ob-lTBxed span{
  color: #fff !important;
}
.goog-te-gadget-simple img{
  display: none !important;
}
.sidebar {
  display: none;
}
.sectiontitle {
  color: var(--secondary-backgroundcolor);
  margin: 50px 0;
  font-size: 45px;
}
.sectiontitletext {
  color: var(--secondary-backgroundcolor);
  font-weight: 700;
  align-items: center;
}
.contactcol {
  text-align: center;
}
.submenu a {
  color: #fff;
  text-decoration: none;
}
.submenu {
  margin-left: -20px !important;
}
.link {
  text-decoration: none;
  color: #fff;
}
.navbartitle:hover {
  cursor: pointer;
  /* color: #000000; */
}
.li1 h4 {
  font-size: 20px;
}
.iconbox img {
  width: 80px;
}
.iconmenu {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  border-radius: 15px;
}
.icontext h5 {
  font-size: 25px;
  margin-top: 10px;
}
.ourproductrow {
  margin: 50px 0 !important;
}
.iconmenu {
  padding: 25px;
}
.iconmenu:hover {
  background: #a37933;
  color: #fff;
}
.iconheding {
  color: #000000;
}
.tradingview-widget-copyright {
  display: none;
}
.contactfrom input,
textarea {
  margin-bottom: 20px;
  width: 90%;
  height: 56px;
  border-radius: 15px;
  background: var(--secondary-backgroundcolor);
  padding: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.contactfrom textarea {
  height: 120px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.contactbtn {
  margin: 50px 0;
}
.contactfrom input::placeholder {
  color: var(--primary-backgroundcolor);
  margin-left: 20px;
}
.contactfrom textarea::placeholder {
  color: var(--primary-backgroundcolor);
}
.logoimg {
  width: 150px;
  padding-left: 10px;
}
.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
  background-color: #a37933 !important;
}
.mainnav {
  font-family: "Open Sans", sans-serif !important;
}
.socialmediawidget {
  position: fixed;
  right: 0;
  top: 50%;
  display: flex;
  justify-content: center;
  z-index: 100000;
  flex-direction: column;
}
.socialmediawidget a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  text-decoration: none;
  color: #fff;
}
.socialmediawidget i {
  font-size: 20px;
}
.socialmediawidget a:hover {
  color: #fff;
}
.facebook {
  background: #3b5998;
}
.facebook:hover,
.instagram:hover,
.whatsapp:hover {
  background: #000;
}
.instagram {
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
}
.twitter {
  background: #000;
}
.twitter:hover {
  background: #55acee;
}
.whatsapp {
  background: #25d366;
}
.telegarm {
  background: #0088cc;
}
.telegarm:hover {
  background: #000;
}
.iconofdesktop svg {
  color: #000 !important;
}
.tiltleofdeskto h3,
.desktoplink a {
  color: #fff !important;
}
.goog-te-gadget-simple .VIpgJd-ZVi9od-xl07Ob-lTBxed span {
  text-decoration: none;
  border-left: none !important;
}
@media only screen and(max-width:1024px) {
    .desktop-lang{
      visibility: hidden;
    }
}
/* 1024 */
@media only screen and (min-width: 1024px) and (max-width: 1279px) {
  .newlook {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .newlook2 {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .newlook {
    background: #8c7f7069;
    padding: 15px;
    border-radius: 50%;
    height: 100px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #ca9a08;
  }
  .desktop {
    background-image: url(/public/images/mt52.jpg);
    height: 300px;
    width: 300px;
    display: flex;
    justify-content: end;
    align-items: flex-start;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    flex-direction: column;
    padding: 30px;
    margin: 20px;
    border-radius: 10px;
  }
  .desktop:hover {
    background-blend-mode: multiply;
    background-color: #0000004f;
    cursor: pointer;
  }
  .iconofdesktop {
    margin: 10px 0;
  }
  .tiltleofdeskto h3 {
    color: #000;
    font-size: 38px;
    margin: 10px 0;
  }
  .desktoplink a {
    color: #000;
    margin-right: 20px;
  }
  .desktoplink svg {
    margin: 0 5px;
  }
  .desktoplink a:hover {
    color: #ffff;
  }
  .sliderimg img {
    width: 100%;
    margin-top: 50px;
  }
  .piphegit {
    height: 650px !important;
  }
  .marginhegiht {
    height: 523px !important;
  }
  .marginclac {
    margin-left: 15% !important;
  }
  .pricelist {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background: #000;
    border-radius: 20px;
  }
  .titleofpricelist {
    background: #ca9a36;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
    border-radius: 19px 19px 0 0;
  }
  .detailsofpricelist ul {
    color: #fff;
    list-style: none;
    padding-top: 20px;
  }
  .detailsofpricelist ul hr {
    width: 90%;
    margin: 15px 0;
  }
  .linkofpricelist {
    padding: 10px;
    background: #ca9a36;
    border-radius: 0 0 19px 19px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .linkofpricelist:hover {
    background: #fff;
  }
  .linkofpricelist a {
    text-decoration: none;
    color: #000;
    font-size: 20px;
    font-weight: 600;
  }
  .linkofpricelist a:hover {
    color: #000;
  }

  .liofpricelist {
    display: flex;
    align-items: center;
  }
  .liofpricelist i {
    background: var(--secondary-backgroundcolor);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 30px;
    font-size: 15px;
  }
  .textofpricelist {
    font-size: 15px;
    color: #fff;
  }
  .textofpricelist span {
    color: var(--secondary-backgroundcolor);
    font-weight: 600;
  }

  .mainnav {
    display: flex;
    background: #16020a;
    justify-content: space-between;
    align-items: center;
  }
  .logoul {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .logoul a {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .desktoplogo {
    width: 120px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .leftsidedetauls {
    color: #fff;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .leftsidedetauls span {
    font-size: 12px;
    margin: 0 3px;
    color: #000;
  }
  .leftsidedetauls span:hover {
    color: #d39c60;
  }

  .leftsidedetauls i {
    margin: 0 5px;
    font-size: 12px;
    color: #d39c60;
  }
  .headerbtn {
    margin: 0 10px;
  }
  .headerbtn a {
    color: #000;
    text-decoration: none;
    font-size: 12px;
    margin: 0 10px;
    border-right: 1px solid #000;
    padding: 0 10px;
  }
  .headerbtn a i {
    color: var(--secondary-backgroundcolor);
    margin: 0 5px;
  }
  .headerbtn a:hover {
    color: var(--secondary-backgroundcolor);
  }

  .li1 {
    position: relative;
  }
  .li1 h4 {
    color: #fff;
    font-size: 15px;
    margin: 0 15px;
  }
  .li1 h4:hover {
    color: #a37933;
  }
  .ibmaintext {
    margin: 100px 0 !important;
  }
  .slider {
    background-image: url(/public/images/slider-1.jpg);
    background-color: rgba(0, 0, 1, 0.5);
    background-blend-mode: multiply;
    height: 500px !important;
  }
  .slider2 {
    background-image: url(/public/images/slider-2.jpg) !important;
    background-color: rgba(0, 0, 1, 0.5) !important;
    background-blend-mode: multiply !important;
    height: 500px !important;
  }
  .mt5btn a {
    text-decoration: none;
    background: #a37933;
    margin: 0 20px;
    color: #fff;
    width: 50px;
    height: 50px;
    font-size: 25px;
    padding: 20px;
    border-radius: 15px;
    margin: 20px;
  }
  .techniaclstep {
    background: #000;
    height: 250px;
    color: #fff;
  }
  .titletehnical {
    text-align: center;
    padding: 50px;
  }
  .titletehnical h4 {
    margin: 10px 0;
  }
  .technicaltext {
    font-size: 20px;
    text-align: center;
    padding: 30px;
  }
  .tabs {
    display: flex;
    padding: 20px;
    width: 250px;
    justify-content: center;
    margin: 30px 50px;
    border-radius: 25px;
    background: #3f9483;
    color: #000;
  }
  .tabimg {
    margin-right: 10px;
  }
  .bannertext {
    margin-top: 21% !important;
  }
  .bannertext h2 {
    font-size: 80px;
    color: #a37933;
  }
  .imgsider3 img {
    width: 100%;
    margin: 60px 5px;
  }
  .imgsider2 img {
    width: 100%;
  }

  .forfootermobile {
    display: none !important;
  }
  .logoheader {
    display: flex;
    height: auto;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    /* background: var(--primary-backgroundcolor); */
    color: black !important;
    padding: 20px;
  }
  .advatabtext {
    margin-top: 50px !important;
  }

  .whychosebox1 {
    background: #0000006e !important;
    position: relative;
    height: 180px;
    border-radius: 20px;
  }
  .whychosebox1 img {
    position: absolute;
    right: 45px;
  }
  .afterwhychosebox1 {
    background-color: #a37933;
    position: relative;
    height: 200px;
    border-radius: 20px;
  }
  .afterwhychosebox1 img {
    position: absolute;
    right: 45px;
  }
  .textside {
    padding: 20px;
  }
  .textside h3 {
    margin: 10px 0;
    font-size: 40px;
    color: var(--secondary-backgroundcolor);
  }
  .textside h6 {
    margin: 10px 0;
    font-size: 15px;
    color: gray;
  }
  .textside p {
    margin: 20px 0;
    line-height: 30px;
    margin-bottom: 30px;
  }
  .sideimg img {
    width: 90%;
  }
  .mobileviews {
    display: none;
  }
  .sideonepricelist ul,
  .sidetwopricelist ul {
    list-style-image: url(/public/images/icons8-candlestick-chart-30.png);
    font-size: 18px;
    line-height: 40px;
  }
  .ibmain {
    background-image: url(/public/images/16340578_v796-nunny-03b.jpg);
    background-repeat: round;
    width: 100%;
  }
  .ibhome {
    padding: 30px 40px;
    width: 50%;
  }
  .ibhome h3 {
    font-size: 25px;
    padding: 10px 0;
  }
  .ibhome h6 {
    font-size: 15px;
    padding: 10px 0;
    color: #fff;
  }
  .ibhome p {
    line-height: 20px;
    padding: 10px 0;
    color: #fff;
  }
  .textprice p {
    font-size: 22px !important;
  }
  .tabsprice {
    background: #000000 !important;
    color: #fff !important;
    margin-bottom: 33px !important;
    border-radius: 0 30px 0 30px !important;
  }
  .clicktebprice {
    background: #a37933 !important;
    color: #fff !important;
    margin-bottom: 33px !important;
    /* box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px !important; */
    border-radius: 0 30px 0 30px !important;
  }
  .sidebarnew {
    display: none;
  }

  .serviceimg img {
    width: 100%;
  }
  .sidertextimg img {
    width: 70%;
    margin: 20px 0;
  }
  .servicetext {
    font-size: 20px;
    margin: 20px 0;
  }
  .slidertext {
    margin: 150px 50px;
  }
  .slidertext h3 {
    color: #a37933;
    font-size: 35px;
  }
  .slidertext p {
    color: #fff;
  }
  .submenu a {
    color: #fff;
    text-decoration: none;
  }
  .textslider {
    width: 50%;
    margin: 150px 50px;
  }
  .imgside {
    width: 50%;
    padding: 100px 0;
  }
  /* .imgside img{
         margin: 100px !important;
      } */

  .textslider h3 {
    color: #000000cd;
  }
  .textslider p {
    color: #fff;
  }
  .slidermain {
    display: flex;
  }

  .sliederbtn {
    margin: 30px 0;
    display: flex;
    flex-wrap: wrap;
  }
  .sliederbtn a {
    margin: 10px;
  }
  .commanbtn {
    background-color: #3f9483;
    padding: 15px;
    color: #fff;
    border-radius: 20px;
    margin: 0 10px;
    text-decoration: none;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }
  .commanbtn:hover {
    background-color: #fff;
    transition: all 0.8 ease-in-out;
    color: var(--primary-backgroundcolor);
  }

  .mainnav {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  }
  .navbartitle {
    display: flex;
    justify-content: space-around;
    margin: 0 50px;
    list-style: none;
    align-items: center;
    padding: 20px;
  }
  .navbartitle:hover {
    cursor: pointer;
    /* color: #000000; */
  }
  .submenucontanier {
    width: 200px;
    position: absolute;
    height: auto;
    background: #16020a;
    color: #fff;
    z-index: 1;
    left: 0%;
    top: 52px;
    margin-left: -30px;
    border-radius: 0 0 10px 10px;
  }
  .megamenuconatiner {
    /* margin: 30px 0; */
    list-style: none;
  }
  .submenu {
    list-style: none;
    margin: 30px 0;
  }
  .submenu li:hover {
    color: #000000;
  }
  .submenu li {
    font-size: 17px;
    margin: 10px 0;
  }
  .megamenubtnn {
    margin: 50px 0;
  }
  .brosercontaner {
    background: var(--primary-backgroundcolor);
    border-radius: 20px;
  }
  .brosertext {
    color: #a37933;
    margin: 20px 5px;
  }
  .broserimg img {
    width: 50%;
  }
  .dwonloadmain {
    display: flex;
    justify-content: space-around;
  }
  .dwonload {
    background: #000;
    color: #a37933;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    margin: 14px 0;
  }
  .dwonload:hover {
    background: #000000;
    color: #fff;
  }
  .icondwn {
    font-size: 20px;
    margin: 10px;
  }
  .textdwn {
    color: #a37933;
    font-size: small;
    margin-top: 10px;
    text-decoration: none;
  }
}

/* desktop */
@media screen and (min-width: 1025px) {
  .newlook {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .newlook2 {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .newlook {
    background: #8c7f7069;
    padding: 15px;
    border-radius: 50%;
    height: 100px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #ca9a08;
  }

  .desktop {
    background-image: url(/public/images/mt52.jpg);
    height: 350px;
    width: 350px;
    display: flex;
    justify-content: end;
    align-items: flex-start;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    flex-direction: column;
    padding: 30px;
    margin: 20px;
    border-radius: 10px;
  }
  .desktop:hover {
    background-blend-mode: multiply;
    background-color: #0000004f;
    cursor: pointer;
  }
  .iconofdesktop {
    margin: 10px 0;
  }
  .tiltleofdeskto h3 {
    color: #000;
    font-size: 38px;
    margin: 10px 0;
  }
  .desktoplink a {
    color: #000;
    margin-right: 20px;
  }
  .desktoplink svg {
    margin: 0 5px;
  }
  .desktoplink a:hover {
    color: #ffff;
  }
  .slider {
    background-image: url(/public/images/slider-1.jpg);
    background-color: rgba(0, 0, 1, 0.5);
    background-blend-mode: multiply;
    height: 500px !important;
  }

  .marginhegiht {
    height: 523px !important;
  }
  .piphegit {
    height: 650px !important;
  }
  .logoheader {
    display: flex;
    height: auto;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    /* background: var(--primary-backgroundcolor); */
    color: black !important;
    padding: 20px;
  }
  .marginclac {
    margin-left: 25% !important;
  }
  .pricelist {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background: #000;
    border-radius: 20px;
  }
  .titleofpricelist {
    background: #ca9a36;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
    border-radius: 19px 19px 0 0;
  }
  .detailsofpricelist ul {
    color: #fff;
    list-style: none;
    padding-top: 20px;
  }
  .detailsofpricelist ul hr {
    width: 90%;
    margin: 15px 0;
  }
  .linkofpricelist {
    padding: 10px;
    background: #ca9a36;
    border-radius: 0 0 19px 19px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .linkofpricelist:hover {
    background: #fff;
  }
  .linkofpricelist a {
    text-decoration: none;
    color: #000;
    font-size: 20px;
    font-weight: 600;
  }
  .linkofpricelist a:hover {
    color: #000;
  }

  .liofpricelist {
    display: flex;
    align-items: center;
  }
  .liofpricelist i {
    background: var(--secondary-backgroundcolor);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 30px;
    font-size: 15px;
  }
  .textofpricelist {
    font-size: 15px;
    color: #fff;
  }
  .textofpricelist span {
    color: var(--secondary-backgroundcolor);
    font-weight: 600;
  }

  .singlemenu {
    color: #fff;
    font-weight: 300;
  }
  .singlemenu:hover {
    color: var(--secondary-backgroundcolor);
  }
  .mainnav {
    display: flex;
    background: #16020a;
    justify-content: space-between;
    align-items: center;
  }
  .logoul {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .logoul a {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .desktoplogo {
    width: 120px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .leftsidedetauls {
    color: #fff;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .leftsidedetauls span {
    font-size: 15px;
    margin: 0 3px;
    color: #000;
  }
  .leftsidedetauls span:hover {
    color: #d39c60;
  }

  .leftsidedetauls i {
    margin: 0 5px;
    font-size: 15px;
    color: #d39c60;
  }
  .headerbtn {
    margin: 0 10px;
  }
  .headerbtn a {
    color: #000;
    text-decoration: none;
    font-size: 14px;
    margin: 0 10px;
    border-right: 1px solid #000;
    padding: 0 10px;
  }
  .headerbtn a i {
    color: var(--secondary-backgroundcolor);
    margin: 0 5px;
  }
  .headerbtn a:hover {
    color: var(--secondary-backgroundcolor);
  }
  .li1 {
    position: relative;
    color: #fff;
  }
  .li1 h4 {
    margin: 0 20px;
    font-size: 17px;
  }
  .li1 h4:hover {
    color: #a37933;
  }
  .slider {
    background-image: url(/public/images/slider-1.jpg);
    background-color: rgba(0, 0, 1, 0.5);
    background-blend-mode: multiply;
    height: 500px !important;
  }
  .slider2 {
    background-image: url(/public/images/slider-2.jpg) !important;
    background-color: rgba(0, 0, 1, 0.5) !important;
    background-blend-mode: multiply !important;
    height: 500px !important;
  }
  .ibmaintext {
    padding: 50px 0 !important;
  }
  .mt5btn a {
    text-decoration: none;
    background: #a37933;
    margin: 0 20px;
    color: #fff;
    width: 50px;
    height: 50px;
    font-size: 25px;
    padding: 20px;
    border-radius: 15px;
  }
  .techniaclstep {
    background: #000;
    height: 250px;
    color: #fff;
  }
  .titletehnical {
    text-align: center;
    padding: 50px;
  }
  .titletehnical h4 {
    margin: 30px 0;
  }
  .technicaltext {
    font-size: 20px;
    text-align: center;
    padding: 50px;
  }
  .tabs {
    display: flex;
    padding: 20px;
    width: 250px;
    justify-content: center;
    margin: 0 50px;
    border-radius: 25px;
    background: #3f9483;
    color: #fff;
    border: 2px solid #3f9483;
  }
  .tabimg {
    margin-right: 10px;
  }
  .bannertext h2 {
    font-size: 80px;
    color: #a37933;
  }
  .forfootermobile {
    display: none;
  }
  .imgsider3 img {
    width: 87%;
    margin: 20px;
  }
  .mobileviews {
    display: none;
  }
  .imgsider2 img {
    width: 100%;
    margin-top: -100px;
  }
  .advatabtext {
    margin-top: 100px !important;
  }
  .sliderimg img {
    width: 95%;
    margin: 35px 30px;
  }

  .whychosebox1 {
    background: #16020a !important;
    position: relative;
    height: 180px;
    border-radius: 20px;
  }
  .whychosebox1 img {
    position: absolute;
    right: 78px;
    top: -60px;
    width: 50%;
  }
  .afterwhychosebox1 {
    background-color: #16020a !important;
    position: relative;
    height: 200px;
    border-radius: 20px;
  }
  .afterwhychosebox1 img {
    position: absolute;
    right: 60px;
    top: -60px;
  }
  .ibmain {
    background-image: url(/public/images/16340578_v796-nunny-03b.jpg);
    background-repeat: round;
    width: 100%;
    margin-bottom: 0 !important;
  }
  .ibhome {
    padding: 40px 50px;
    width: 50%;
  }
  .ibhome h3 {
    font-size: 35px;
    padding: 20px 0;
  }
  .ibhome h6 {
    font-size: 20px;
    padding: 10px 0;
    color: #fff;
  }
  .ibhome p {
    line-height: 30px;
    padding: 10px 0;
    color: #fff;
  }
  .sidebarnew {
    display: none;
  }
  .sideonepricelist ul,
  .sidetwopricelist ul {
    list-style-image: url(/public/images/icons8-candlestick-chart-30.png);
    font-size: 18px;
    line-height: 40px;
  }
  .textside {
    padding: 30px;
  }
  .textside h3 {
    margin: 10px 0;
    font-size: 50px;
    color: var(--secondary-backgroundcolor);
  }
  .textside h6 {
    margin: 10px 0;
    font-size: 20px;
    color: gray;
  }
  .textside p {
    margin: 20px 0;
    line-height: 30px;
    margin-bottom: 30px;
  }
  .textprice p {
    font-size: 22px !important;
  }
  .tabsprice {
    background: #3f9483 !important;
    color: #fff !important;
    margin-bottom: 33px !important;

    /* box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px !important; */
    border-radius: 0 30px 0 30px !important;
  }

  .clicktebprice {
    background: #a37933 !important;
    color: #fff !important;
    margin-bottom: 33px !important;
    border-radius: 0 30px 0 30px !important;

    /* box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px !important; */
  }

  .imgside {
    margin: 100px !important;
  }
  .brosercontaner {
    background: var(--primary-backgroundcolor);
    border-radius: 20px;
  }
  .servicetext {
    padding: 12px;
    font-size: 20px;
    line-height: 35px;
  }
  .sidertextimg img {
    width: 50%;
    margin: 20px 0;
  }
  .brosertext {
    color: #a37933;
    margin: 50px 5px;
  }
  .broserimg img {
    width: 67%;
  }
  .dwonloadmain {
    display: flex;
    justify-content: space-evenly;
  }
  .dwonload {
    background: #fff;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    margin: 30px 0;
  }
  .dwonload:hover {
    background: #a37933;
    color: #000000 !important;
  }
  .icondwn {
    font-size: 30px;
    margin: 10px;
  }
  .textdwn {
    margin-top: 10px;
    margin-right: 5px;
    text-decoration: none;
    color: #a37933;
  }
  .submenu a {
    color: #fff;
    text-decoration: none;
  }

  .slidertext {
    margin: 150px 50px;
  }
  .slidertext h3 {
    color: #d39c60;
    font-size: 60px;
  }
  .slidertext p {
    color: #fff;
    font-size: 20px;
  }
  .slidermain {
    display: flex;
    justify-content: space-around;
  }
  .textslider {
    width: 50%;
    margin: 150px 50px;
  }
  .textslider h3 {
    color: #000000cd;
    font-weight: 500;
    font-size: 40px;
  }
  .textslider p {
    color: #fff;
  }

  .sliederbtn {
    margin: 30px 0;
  }
  .commanbtn {
    background-color: #3f9483;
    padding: 15px;
    color: #fff;
    border-radius: 20px;
    margin: 0 10px;
    text-decoration: none;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }
  .commanbtn:hover {
    background-color: #fff;
    transition: all 0.8 ease-in-out;
    color: var(--primary-backgroundcolor);
  }

  .mainnav {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  }
  .navbartitle {
    display: flex;
    justify-content: space-around;
    margin: 0 50px;
    list-style: none;
    align-items: center;
    padding: 20px;
  }
  .navbartitle:hover {
    cursor: pointer;
    /* color: #a37933; */
  }
  .submenucontanier {
    width: 200px;
    position: absolute;
    height: auto;
    background: #16020a;
    color: #fff;
    z-index: 1;
    left: 0%;
    top: 52px;
    margin-left: -30px;
    border-radius: 0 0 10px 10px;
  }
  .megamenuconatiner {
    list-style: none;
  }
  .submenu {
    list-style: none;
    margin: 30px 0;
  }
  .submenu li:hover {
    color: #a37933;
  }
  .submenu li {
    font-size: 17px;
    margin: 10px 0;
  }
  .megamenubtnn {
    margin: 50px 0;
  }
}

/* ///mobile//  */
@media only screen and (max-width: 767px) {
  .mobilemargin {
    margin-top: 80px !important;
  }
  .sidebarnav {
    padding-left: 10px !important;
  }
  .techniaclstep {
    background: #000;
    height: 250px;
    color: #fff;
    margin: 10px 10px;
  }
  .titletehnical {
    text-align: center;
    padding: 50px;
  }
  .titletehnical h4 {
    margin: 30px 0;
  }
  .technicaltext {
    font-size: 20px;
    text-align: center;
    padding: 50px;
  }
  .sliderimg img {
    width: 100%;
  }
  .imgsider3 img {
    width: 100%;
    padding: 26px 0;
  }
  .newlook {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .newlook2 {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .newlook {
    background: #8c7f7069;
    padding: 15px;
    border-radius: 50%;
    height: 100px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #ca9a08;
  }
  .desktop {
    background-image: url(/public/images/mt52.jpg);
    height: 300px;
    width: 300px;
    display: flex;
    justify-content: end;
    align-items: flex-start;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    flex-direction: column;
    padding: 20px;
    margin: 10px 0;
    border-radius: 10px;
  }
  .desktop:hover {
    background-blend-mode: multiply;
    background-color: #0000004f;
    cursor: pointer;
  }
  .iconofdesktop {
    margin: 10px 0;
  }
  .tiltleofdeskto h3 {
    color: #000;
    font-size: 38px;
    margin: 10px 0;
  }
  .desktoplink a {
    color: #000;
    margin-right: 20px;
  }
  .desktoplink svg {
    margin: 0 5px;
  }
  .desktoplink a:hover {
    color: #ffff;
  }
  .pricelist {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background: #000;
    border-radius: 20px;
    margin: 30px 0;
  }
  .piphegit {
    height: 830px !important;
  }
  .marginhegiht {
    height: 750px !important;
  }
  .titleofpricelist {
    background: #ca9a36;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
    border-radius: 19px 19px 0 0;
  }
  .detailsofpricelist ul {
    color: #fff;
    list-style: none;
    padding-top: 20px;
  }
  .detailsofpricelist ul hr {
    width: 90%;
    margin: 15px 0;
  }
  .linkofpricelist {
    padding: 10px;
    background: #ca9a36;
    border-radius: 0 0 19px 19px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .linkofpricelist:hover {
    background: #fff;
  }
  .linkofpricelist a {
    text-decoration: none;
    color: #000;
    font-size: 20px;
    font-weight: 600;
  }
  .linkofpricelist a:hover {
    color: #000;
  }

  .liofpricelist {
    display: flex;
    align-items: center;
  }
  .liofpricelist i {
    background: var(--secondary-backgroundcolor);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 30px;
    font-size: 15px;
  }
  .textofpricelist {
    font-size: 15px;
    color: #fff;
  }
  .textofpricelist span {
    color: var(--secondary-backgroundcolor);
    font-weight: 600;
  }

  .forfootermobile {
    display: block !important;
  }
  ul.listPLesd li {
    width: 100% !important;
  }
  .logoimg {
    width: 100px;
  }
  .imgcol1 {
    order: -1 !important;
  }
  .ibmaintext {
    margin: 80px 0 !important;
  }
  .Tradenowbtncol {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .bannertext h2 {
    font-size: 50px;
    color: #a37933;
  }
  .sideimg img {
    width: 100% !important;
    margin: 0 !important;
    padding: 0;
  }
  .mt5dwn {
    text-align: center;
  }
  .dwonloadmain {
    display: flex;
    flex-wrap: wrap;
  }
  .imgadavntage {
    width: 60% !important;
    position: absolute !important;
    top: -100px !important;
    right: 60px !important;
    z-index: 0;
  }
  .tabs {
    display: flex;
    padding: 10px;
    width: 180px;
    justify-content: center;
    margin: 10px 20px;
    border-radius: 25px;
    background: #3f9483;
    color: #000;
    font-size: small;
  }
  .tabimg {
    margin-right: 10px;
  }
  .bannertext {
    margin-top: 27% !important;
  }
  .footrrow {
    display: none !important;
  }
  .footerttitle {
    margin: 10px 0px !important;
    color: #a37933;
  }
  .topbar {
    display: flex;
    justify-content: space-evenly;
  }
  .imgside {
    width: 100%;
    padding: 20px 0 !important;
  }
  .MuiToolbar-root {
    display: flex;
    justify-content: space-between;
  }
  .whychosebox1 {
    background: #0000006e !important;
    position: relative;
    height: 180px;
    border-radius: 20px;
    margin: 50px 0;
  }
  .whychosebox1 img {
    position: absolute;
    right: 60px !important;
    top: -80px !important;
  }
  .afterwhychosebox1 {
    background-color: #0000006e !important;
    position: relative;
    height: 200px;
    border-radius: 20px;
    margin: 50px 0;
  }
  .afterwhychosebox1 img {
    position: absolute;
    right: 60px !important;
    top: -80px !important;
  }
  .textside {
    padding: 20px;
    text-align: center;
  }
  .textside h3 {
    margin: 10px 0;
    font-size: 30px;
    color: var(--secondary-backgroundcolor);
  }
  .textside h6 {
    margin: 10px 0;
    font-size: 15px;
    color: gray;
  }
  .textside p {
    margin: 20px 0;
    line-height: 20px;
    margin-bottom: 30px;
  }
  .sideimg {
    text-align: center;
  }
  .sideimg img {
    width: 90%;
    padding: 50px 0;
    text-align: center;
  }
  .MuiBox-root {
    margin-bottom: 47px !important;
  }
  .vartical {
    display: none;
  }
  .ibmain {
    background-image: url(/public/images/16340578_v796-nunny-03b.jpg);
    background-repeat: round;
    width: 100%;
    height: 440px;
    text-align: center;
  }
  .ibhome {
    /* width: 50%; */
    padding-top: 10px !important;
    width: 100%;
    text-align: center;
  }

  .ibhome h6 {
    /* padding: 10px 0; */
    color: #fff;
  }
  .ibhome p {
    /* padding: 10px 0; */
    color: #fff;
  }
  .sideonepricelist ul,
  .sidetwopricelist ul {
    list-style-image: url(/public/images/icons8-candlestick-chart-30.png);
    font-size: 18px;
    line-height: 30px;
  }

  .css-heg063-MuiTabs-flexContainer {
    flex-wrap: wrap;
  }
  .textprice p {
    font-size: 15px !important;
  }
  .tabsprice {
    background: #000000 !important;
    color: #fff !important;
    border-radius: 0 30px 0 30px !important ;
    /* box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px !important; */
  }
  .clicktebprice {
    background: #000 !important;
    color: #fff !important;
    margin-bottom: 25px !important;
    /* box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px !important; */
    margin-left: 20px !important;
    border-radius: 0 30px 0 30px !important;
  }
  .sidebarnav li {
    margin: 25px 0;
    list-style: none;
    margin-left: 0 !important;
  }
  .sidebarnav li ul li {
    color: #000;
    text-decoration: none !important;
  }
  .sidebarnav li ul li:hover {
    color: #000000;
    text-decoration: none !important;
  }
  .btnsidebar {
    background: #000000;
    padding: 10px;
    border-radius: 10px;
    color: #fff;
    list-style: none;
    text-decoration: none;
  }
  .btnsidebar:hover {
    background: #000;
    padding: 10px;
    border-radius: 10px;
    color: #000000;
    list-style: none;
    text-decoration: none;
  }

  .slider {
    background-image: url(/public/images/slider-1.jpg);
    background-color: rgba(0, 0, 1, 0.5);
    background-blend-mode: multiply;
    height: 750px !important;
    margin-top: 50px;
  }

  .slider2 {
    background-image: url(/public/images/slider-2.jpg) !important;
    background-color: rgba(0, 0, 1, 0.5) !important;
    background-blend-mode: multiply !important;
    height: 750px !important;
    margin-top: 50px;
  }
  .sidetext {
    text-align: center;
    margin: 50px 0;
  }
  .servicetext {
    margin: 50px 0;
  }
  .imgcol {
    order: 1 !important;
  }
  .slidertext {
    text-align: center;
  }
  .slidertext h3 {
    font-size: 25px;
    padding: 20px 0;
    color: #fff;
  }
  .sliederbtn {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .logoheader {
    display: none !important;
    height: 100px;
    width: 100%;
    /* background: var(--primary-backgroundcolor); */
    color: black !important;
    padding: 30px;
    justify-content: space-between;
  }
  .navbartitle {
    display: block;
  }
  .mainnav {
    position: absolute;
    background: #000;
    opacity: 0.8;
    width: 100%;
    color: #fff;
    z-index: 1;
  }

  .imgside {
    order: 1;
  }
  .headerbtn {
    display: none;
  }
  .mobileview {
    display: none;
  }
  .sidebar {
    display: block;
    color: #fff;
  }
  .submenu {
    list-style: none !important;
    margin: 10px 0;
  }
  .submenu a {
    color: #fff !important;
    font-weight: 500;
  }
  .submenu {
    position: static;
  }
  .submenu li {
    font-size: 20px;
    margin: 5px 0;
  }
  .megamenuconatiner {
    list-style: none;
  }
  .navbartitle {
    list-style: none;
    margin: 30px 0;
  }
  .mobiletitle {
    display: flex;
  }
  .mobiletitle i {
    padding: 5px;
  }
  .submenucontanier {
    left: 0;
    background: #000;
  }

  .slidertext h3 {
    color: var(--secondary-backgroundcolor);
    font-size: 35px;
    margin: 20px 0;
  }
  .slidertext p {
    color: #fff;
  }

  .commanbtn {
    background-color: #3f9483;
    padding: 15px;
    color: #fff;
    border-radius: 20px;
    margin: 10px 10px;
    text-decoration: none;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }
  .commanbtn:hover {
    background-color: #fff;
    transition: all 0.8 ease-in-out;
    color: var(--primary-backgroundcolor);
  }
}
/* //tablete */
@media only screen and (max-width: 1023px) and (min-width: 768px) {
  .mobilemargin {
    margin-top: 80px !important;
  }
  .bannertext h2 {
    font-size: 80px;
    color: #a37933;
  }
  .commanbtn {
    background-color: #3f9483;
    padding: 15px;
    color: #fff;
    border-radius: 20px;
    margin: 0 10px;
    text-decoration: none;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }
  .commanbtn:hover {
    background-color: #fff;
    transition: all 0.8 ease-in-out;
    color: var(--primary-backgroundcolor);
  }
  .sliderimg img {
    width: 100%;
  }
  .imgsider3 img {
    width: 100%;
  }
  .newlook {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .newlook2 {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .newlook {
    background: #8c7f7069;
    padding: 15px;
    border-radius: 50%;
    height: 100px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #ca9a08;
  }
  .desktop {
    background-image: url(/public/images/mt52.jpg);

    height: 350px;
    width: 350px;
    display: flex;
    justify-content: end;
    align-items: flex-start;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    flex-direction: column;
    padding: 30px;
    margin: 20px;
    border-radius: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
  .desktop:hover {
    background-blend-mode: multiply;
    background-color: #0000004f;
    cursor: pointer;
  }
  .iconofdesktop {
    margin: 10px 0;
  }
  .tiltleofdeskto h3 {
    color: #000;
    font-size: 38px;
    margin: 10px 0;
  }
  .desktoplink a {
    color: #000;
    margin-right: 20px;
  }
  .desktoplink svg {
    margin: 0 5px;
  }
  .desktoplink a:hover {
    color: #ffff;
  }
  .logoheader {
    display: none;
    height: 100px;
    width: 100%;
    background: var(--secondary-backgroundcolor);
    color: black !important;
    padding: 30px;
    justify-content: space-between;
  }

  .marginclac {
    margin-left: 0% !important;
  }
  .pricelist {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background: #000;
    border-radius: 20px;
  }
  .titleofpricelist {
    background: #ca9a36;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
    border-radius: 19px 19px 0 0;
  }
  .detailsofpricelist ul {
    color: #fff;
    list-style: none;
    padding-top: 20px;
  }
  .detailsofpricelist ul hr {
    width: 90%;
    margin: 15px 0;
  }
  .linkofpricelist {
    padding: 10px;
    background: #ca9a36;
    border-radius: 0 0 19px 19px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .linkofpricelist:hover {
    background: #fff;
  }
  .linkofpricelist a {
    text-decoration: none;
    color: #000;
    font-size: 20px;
    font-weight: 600;
  }
  .linkofpricelist a:hover {
    color: #000;
  }

  .liofpricelist {
    display: flex;
    align-items: center;
  }
  .liofpricelist i {
    background: var(--secondary-backgroundcolor);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 30px;
    font-size: 15px;
  }
  .textofpricelist {
    font-size: 15px;
    color: #fff;
  }
  .textofpricelist span {
    color: var(--secondary-backgroundcolor);
    font-weight: 600;
  }

  .slider {
    background-image: url(/public/images/slider-1.jpg);
    background-color: rgba(0, 0, 1, 0.5);
    background-blend-mode: multiply;
    height: 500px;
    margin-top: 80px;
  }
  .slidertext {
    margin: 100px 50px;
  }

  .slider2 {
    background-image: url(/public/images/slider-2.jpg) !important;
    background-color: rgba(0, 0, 1, 0.5) !important;
    background-blend-mode: multiply !important;
    height: 500px;
    margin-top: 80px;
  }
  .mt5btn a {
    text-decoration: none;
    background: #a37933;
    margin: 0 20px;
    color: #fff;
    width: 50px;
    height: 50px;
    font-size: 15px;
    padding: 15px;
    border-radius: 15px;
  }

  .iconimage {
    margin: 30px 0;
  }
  .techniaclstep {
    background: #000;
    height: 250px;
    color: #fff;
    margin: 10px 10px;
  }
  .titletehnical {
    text-align: center;
    padding: 50px;
  }
  .titletehnical h4 {
    margin: 30px 0;
  }
  .technicaltext {
    font-size: 20px;
    text-align: center;
    padding: 50px;
  }
  .forfootermobile {
    display: none;
  }
  .whychosebox1 {
    background: #0000006e !important;
    position: relative;
    height: 180px;
    border-radius: 20px;
    margin: 50px 0;
  }
  .whychosebox1 img {
    position: absolute;
    right: 66px;
    top: -60px;
  }
  .afterwhychosebox1 {
    background-color: #0000006e !important;
    position: relative;
    height: 200px;
    border-radius: 20px;
    margin: 50px 0;
  }
  .afterwhychosebox1 img {
    position: absolute;
    right: 66px;
    top: -60px;
  }
  .advatabtext {
    margin-top: 130px !important;
  }
  .textside {
    padding: 20px;
  }
  .textside h3 {
    margin: 10px 0;
    font-size: 30px;
    color: var(--secondary-backgroundcolor);
  }
  .textside h6 {
    margin: 10px 0;
    font-size: 15px;
    color: gray;
  }
  .textside p {
    margin: 20px 0;
    line-height: 20px;
    margin-bottom: 30px;
  }
  .sideimg img {
    width: 90%;
    padding: 50px 0;
  }
  .ibmain {
    background-image: url(/public/images/16340578_v796-nunny-03b.jpg);
    background-repeat: round;
    width: 100%;
    height: 400px;
    text-align: center;
  }
  .ibhome {
    padding: 30px 40px;
    /* width: 50%; */
    width: 100%;
    text-align: center;
  }
  .ibhome h3 {
    font-size: 20px;
    padding: 10px 0;
  }
  .ibhome h6 {
    padding: 10px 0;
    color: #fff;
  }
  .ibhome p {
    padding: 10px 0;
    color: #fff;
  }
  .mobileviews {
    display: block;
  }
  .MuiTabs-flexContainer {
    justify-content: center;
  }
  .vartical {
    display: none;
  }
  .sideonepricelist ul,
  .sidetwopricelist ul {
    list-style-image: url(/public/images/icons8-candlestick-chart-30.png);
    font-size: 18px;
    line-height: 30px;
  }
  .textprice p {
    font-size: 15px !important;
  }
  .tabsprice {
    background: #000000 !important;
    color: #fff !important;
    margin-bottom: 25px !important;
    /* box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px !important; */
    margin-left: 20px !important;
    border-radius: 0 30px 0 30px !important;
  }
  .clicktebprice {
    background: #a37933 !important;
    color: #fff !important;
    margin-bottom: 25px !important;
    /* box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px !important;
      */
    border-radius: 0 30px 0 30px !important;
    margin-left: 20px !important;
  }
  .sidebarnav {
    padding-left: 0 !important;
  }
  .sidebarnav li {
    margin: 25px 0;
    list-style: none;
    margin-left: 0 !important;
  }
  .sidebarnav li ul a {
    color: #000;
    text-decoration: none !important;
  }
  .sidebarnav li ul li {
    color: #000;
  }

  .sidebarnav li ul li:hover {
    color: #000000;
    text-decoration: none !important;
  }
  .btnsidebar {
    background: #000000;
    padding: 10px;
    border-radius: 10px;
    color: #fff;
    list-style: none;
    text-decoration: none;
    font-size: 12px;
  }
  .btnsidebar:hover {
    background: #000;
    padding: 10px;
    border-radius: 10px;
    color: #000000;
    list-style: none;
    text-decoration: none;
  }
  .MuiBox-root {
    margin-bottom: 60px;
  }
  .MuiToolbar-root {
    display: flex;
    justify-content: space-between;
  }
  .sidebarnew {
    display: block;
  }

  .iconheding {
    color: #000000;
    font-size: 20px;
  }
  .servicetext {
    font-size: 15px;
  }
  .brosercontaner {
    background: var(--primary-backgroundcolor);
    border-radius: 20px;
  }
  .brosertext {
    color: #fff;
    margin: 50px 5px;
    text-align: center;
  }
  .broserimg img {
    width: 50%;
  }
  .dwonloadmain {
    display: flex;
    justify-content: space-evenly;
  }
  .dwonload {
    background: #fff;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    margin: 30px 0;
  }
  .dwonload:hover {
    background: #000000;
    color: #fff !important;
  }
  .icondwn {
    font-size: 30px;
    margin: 10px;
  }
  .textdwn {
    margin-top: 10px;
    margin-right: 5px;
    text-decoration: none;
    color: #000;
  }
  .slidermain {
    display: flex;
  }
  .sliederbtn {
    display: flex;
    flex-wrap: wrap;
  }
  .sliederbtn .commanbtn {
    margin: 5px 0;
  }
  .slidertext {
    /* margin: 30px 50px; */
  }
  .slidertext h3 {
    color: #a37933;
    font-size: 25px;
  }
  .slidertext p {
    color: #fff;
  }
  .commanbtn {
    margin: 5px 5px !important;
  }
  .mainnav {
    position: absolute;
    background: #000;
    opacity: 0.8;
    width: 100%;
    color: #fff;
    z-index: 1;
    list-style: none;
  }

  .headerbtn {
    display: none;
  }
  .mobileview {
    display: none;
  }
  .sidebar {
    display: block;
    color: #fff;
  }

  .mobiletitle {
    display: flex;
  }
  .mobiletitle i {
    padding: 5px;
  }
  .submenu {
    list-style: none;
  }
  .submenu {
    list-style: none !important;
    margin: 10px 0;
  }
  .submenu a {
    color: #fff !important;
    font-weight: 500;
  }
  .submenu {
    position: static;
  }
  .submenu li {
    font-size: 20px;
    margin: 5px 0;
  }
  .megamenuconatiner {
    list-style: none;
  }
  .navbartitle {
    list-style: none;
    margin: 30px 0;
  }
  .mobiletitle {
    display: flex;
  }
  .mobiletitle i {
    padding: 5px;
  }
  .submenucontanier {
    left: 0;
    background: #000;
  }
  .navbartitle {
    display: block;
  }
}
.sideprocelist {
  display: flex;
}

.advantagesbox {
  height: 400px;
  background: #000000;
  position: relative;
}
.imgmain img {
  width: 100%;
  position: absolute;
  top: -100px;
}
.advantagestext {
  padding-top: 120px;
}
.advantagesbox:hover {
  background: #fff;
  border: 2px solid black;
}
/* /// */

.adavnatgesection {
  background: transparent
    linear-gradient(90deg, #000000 0%, #292727 52%, #020202 100%);
}
.advatages2 {
  background: #3f9483;
  position: relative;
  color: #000000;
  border-radius: 20px;
  height: 400px;
  margin: 50px 10px;
  box-shadow: 0 0 2rem rgba(219, 225, 235, 0.7);
  -webkit-box-shadow: 0 0 2rem rgba(176, 176, 185, 0.7);
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}
.imgadavntage {
  width: 70%;
  position: absolute;
  top: -90px;
  right: 60px;
  z-index: 0;
}
.setadvimg {
  width: 60%;
  transform: scale(0.7);
  transition: all 0.5s ease-in;
  position: relative;
  top: -100px;
  left: 10px;
}
.advatages {
  padding: 20px;
  border-radius: 20px;
  background: #000000;
  position: relative;
  height: 400px;
  margin: 50px 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.advatabtext h4 {
  color: #a37933;
  text-align: center;
  /* margin-top: 20px; */
}
.advatabtext p {
  color: #fff;
  text-align: center;
  margin-top: 40px;
}
.advantegeslist {
  list-style-image: url(/public/images/icons8-candlestick-chart-24.png);
  /* margin-left: 10px; */
  font-size: small;
}
.advatabtext2 {
  display: none;
}
.advantegeslist {
  margin-top: -100px;
}

.frontside {
  padding-top: 133px;
  color: var(--primary-backgroundcolor);
}
.frontside h4 {
  color: #d39c60;
}
.backside {
  color: var(--secondary-backgroundcolor);
  background-color: #fff;
  position: absolute;
  width: 100%;
  top: 163px;
  transition: all 0.8s ease-in-out;
  border-radius: 0 0 20px 20px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px;
}
.afterbox {
  background-color: #000000;
  position: relative;
  height: 200px;
  border-radius: 20px;
}

.whychoserow {
  margin: 50px 0 !important;
}
.css-ciy9n4-MuiPaper-root-MuiAppBar-root {
  background-color: #fff !important;
}
.css-hyum1k-MuiToolbar-root {
  background-color: #ffff !important;
}
.css-1k455el {
  background: #a37933 !important;
}
.css-jzk4qw-MuiPaper-root-MuiAppBar-root {
  background-color: #fff !important;
}
.css-i6s8oy {
  background-color: #fff !important;
}
.MuiList-root,
.MuiList-root {
  background: #a37933 !important;
}
.footerul li {
  list-style: none;
  color: #fff;
  margin: 5px 0;
}
.footerul li i {
  color: #a37933;
  margin: 0 5px;
}
.footerul li:hover {
  color: #a37933;
}
.footerul li a {
  text-decoration: none;
  color: white;
}
.footerul li a:hover {
  color: #a37933;
}
.footerttitle {
  margin: 20px 30px;
  color: #a37933;
}
.footersection {
  /* background: transparent
    linear-gradient(90deg, #16020a 0%, #16030ad9 52%, #17040b 100%); */
  background: #131313;

  margin-bottom: 0 !important;
}
.socialmediaul {
  display: flex;
  list-style: none;
  justify-content: center;
  margin: 5px 0;
}
.socialmediaul li {
  margin: 0 10px;
  background: #a37933;
  height: 30px;
  width: 30px;
  border-radius: 50%;
}
.socialmediaul li:hover {
  background: #000000;
}
.socialmediaul li i {
  padding: 6px;
}
.socialmediaul li a {
  text-decoration: none;
  color: #fff;
}
.footertext {
  color: #fff;
  padding: 20px;
}
.copyright {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.page-banner {
  margin-top: 0 !important;
  background-color: rgba(0, 0, 1, 0.5);
  background-blend-mode: multiply;
}
.bannertext {
  margin-top: 10%;
}
.bannertext p {
  color: white;
  font-size: 20px;
}
.iconimage img {
  width: 50%;
}
.iconimage h2 {
  color: #000000;
}
.Tradenowbtncol {
  margin: 50px 0 !important;
}
.tradenowtext h2 {
  color: #a37933;
  padding-top: 50px;
  font-size: 40px;
}

.tradesection {
  background: transparent
    linear-gradient(90deg, #000000 0%, #292727 52%, #020202 100%);

  padding: 30px;
}
.acodiantitle button {
  font-size: 20px;
}
.acodianbody {
  background: #000000;
  color: #a37933;
  font-size: 20px;
}
.forextitle h2,
h6 {
  color: #a37933 !important;
  margin: 30px 0;
}
.forextitle p {
  color: #000000;
  line-height: 30px;
  font-size: 20px;
}
.stokesservice {
  margin-top: 100px !important;
}
.feturexul {
  list-style-image: url(/public/images/icons8-candlestick-chart-24.png);
  margin-top: 10px;
}
.feturexul li {
  font-size: 18px;
  margin: 10px 0;
  color: #000000;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}
.advantegesrow {
  margin-top: 100px;
}
.advantegestitle {
  color: #000000;
  margin-bottom: 30px;
}

.mt5btn a:hover {
  background: #fff;
  color: #a37933;
}
.mt5btn {
  margin: 45px 0;
}

.mt5p {
  margin: 20px 0;
  font-size: 20px;
  color: black;
}

.mt5heading {
  color: #a37933;
  padding: 10px 0;
}
.sectionmt5dwonload {
  background: linear-gradient(289deg, #ffffff00, #0e0d0d29),
    url(/public//images/bannerAbout.jpeg);
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;

  padding: 50px 0;
}
.whatis-mt5-title {
  color: #a37933;
  margin: 10px 0;
}
.whatis-mt5 p {
  font-size: 20px;
  line-height: 30px;
}
.tablist {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.clicked {
  background: #a37933 !important;
  color: #000000 !important;
  border: 2px solid #a37933 !important;
}
.listprice {
  display: flex;
  justify-content: space-evenly;
}
.pricelistul {
  list-style: none;
  font-size: 20px;
}
.pricelistul li {
  margin: 10px 0;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.pricelistul2 {
  list-style: none;
  font-size: 20px;
}
.pricelistul2 li {
  margin: 10px 0;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  color: var(--secondary-backgroundcolor);
}
.educationtitle h2 {
  color: var(--secondary-backgroundcolor);
  margin: 10px 0;
}
.educationtitle p {
  font-size: 20px;
  color: #000;
}
.educationtitle {
  margin: 60px 0;
  color: #fff;
}
.educationsection {
  background: linear-gradient(289deg, #ffffff00, #0e0d0d29),
    url(/public/images/bannerAbout.jpeg);
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  padding: 50px 0;
}
.fandamentalstep h4 {
  color: var(--secondary-backgroundcolor);
  margin: 10px 0;
}
.fandamentalstep p {
  color: var(--primary-backgroundcolor);
}
.ibuldiv {
  display: flex;
  flex-wrap: wrap;
}
.ibul {
  list-style: none;
  list-style-image: url(/public/images/icons8-candlestick-chart-30.png);
  font-size: 20px;
  color: var(--secondary-backgroundcolor);
  margin-right: 30px;
}
.ib2div {
  margin: 20px 30px;
}
.ib2div h4 {
  margin: 20px 0;
  color: var(--secondary-backgroundcolor);
}
.ib2div p {
  color: #000000;
  font-size: 20px;
}
.iblast {
  margin-bottom: -79px !important;
}
.ibmaintext {
  margin: 50px 0;
  padding: 5px 0;
}
.ibmaintext h6 {
  color: #fff !important;
  margin: 20px 0;
}
.ibmaintext p {
  color: #fff !important;
  margin: 30px 0;
}
.b1 {
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  /* background: rgb(0,0,0);
   
background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(163,121,51,1) 100%); */
  background-color: #000000;
  /* border: 2px solid red; */
  height: 300px;
  width: 300px;
  margin: 10px 0;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.stepcol {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: end;
}

.steptitle {
  color: #a37933;
}
.stepimg1 {
  margin: 25px 0;
}
.stepimg2 {
  margin: 25px 0;
}
.stepimg3 {
  margin: 25px 0;
}
.imgcol {
  order: 1 !important;
}
.linkbtn {
  background-color: #3f9483;
  padding: 25px;
  border-radius: 25px;
  font-size: medium;
  text-decoration: none;
  color: #fff;
}
.desktop {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
  background-blend-mode: multiply;
  background-color: rgba(0, 0, 0, 0.2);
}
.linkbtn:hover {
  background: var(--secondary-backgroundcolor);
  color: var(--primary-backgroundcolor);
}
.clikdlink {
  background: var(--secondary-backgroundcolor);
  color: var(--primary-backgroundcolor) !important;
  padding: 25px;
  border-radius: 25px;
  font-size: medium;
  text-decoration: none;
}
.producttab {
  margin: 30px 0;
}

/* POP-UP */
.open-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.open-button:hover {
  background-color: #0056b3;
}

.inquiries {
  margin-top: -20px;
}

.inquiries ul {
  list-style: none;
  padding: 0;
}

.inquiry-item {
  margin-bottom: 10px;
  padding: 10px;
  background: #e0e0e0;
  border-radius: 4px;
}
.ReactModal__Content {
  border: none !important;
  background: none !important;
  width: 70% !important;
}

@media only screen and (min-width: 1025px) and (max-width: 1298px) {
  .slidertext h3 {
    font-size: 45px;
  }
  .slidertext {
    margin: 60px 50px;
  }
}
@media only screen and (min-width: 1550px) and (max-width: 2000px) {
  .sliderimg img,
  .imgsider3 img {
    width: 70%;
    margin: 10px;
  }
}
@media only screen and (min-width: 2001px) {
  .sliderimg img,
  .imgsider3 img {
    width: 50%;
    margin: 10px;
  }
}
