div#topNavIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}

div#topNavIcon a {
    color: #fff;
    font-size: 14px;
    border-right: 1px solid #dddddd82;
    padding: 4px 12px;
    margin: 3px 0;
}

div#topNavIcon a:last-child {
    border: 0;
}

/*whatsapp chat*/
a.chat-wp {
    position: fixed;
    z-index: 99;
    bottom: 10px;
    left: 10px;
}
a.chat-wp img {
    width: 50px;
}

/*sliderBanner*/
.sliderMain {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px auto;    
    padding-top: 2%;
}

.sliderMaineLeft {
    width: 60%;
}

.sliderMaineRight {
    width: 40%;
}

.sliderMaineRight img {
    width: 100%;
}

div#sliderMain .swiper-pagination {
    bottom: 40px;
}

div#sliderMain .swiper-pagination span.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: var(--golden);
}

div#sliderMain .swiper-button-prev,
div#sliderMain .swiper-button-next {
    background: var(--golden);
    border: 1px solid #ca9a085c;
    color: #fff;
    padding: 10px;
    width: 38px;
}

div#sliderMain .swiper-button-prev:after,
div#sliderMain .swiper-button-next:after {
    font-size: 20px;
    font-weight: 600;
}
div#toptobottom {
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
    background: #000;
    width: 35px;
    height: 35px;
    text-align: center;
    border-radius: 5px;
    box-shadow: 1px 1px 4px 0px #ca9a085c;
}
div#toptobottom:hover {
    cursor: pointer;
    background: var(--golden);
    color: #000;
}

div#toptobottom:hover i {
    color: #000;
}

div#toptobottom i {
    position: relative;
    top: 7px;
    color: #ffffff57;
}
section#aboutUs {
    position: relative;
}

section#aboutUs {
    background: linear-gradient(289deg, #ffffff00, #0e0d0d29), url(/public/images/standard-banner.jpeg);
    background-size: cover;
    background-color: rgba(0, 0, 1, 0.7) !important;
    background-blend-mode: multiply !important;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    color: #fff;
}

/*---------------------
    Process Section
-----------------------*/

.process:last-child .process-step:after {
    display: none;
}

.process-step {
    position: relative;
    text-align: center;
    padding: 35px;
    margin-top: 30px;
    box-shadow: 0 14px 43px rgba(33, 54, 61, 0.15);
    border-bottom: 3px solid #c1901f;
    border-radius: 5px;
    background: var(--dark);
}

.process-step .process-icon {
    margin-bottom: 20px;
    padding-top: 5px;
}

.process-step h4 {
    margin-bottom: 15px;
    color: var(--green);
}

.process-step p {
    margin-bottom: 0;
    font-weight: 500;
    color: var(--white);
}

.process-step:after {
    position: absolute;
    content: '';
    width: 275px;
    height: 56px;
    top: -40px;
    right: -40%;
    background: url(/public/images/process-line.png) no-repeat scroll 0 0/cover;
    z-index: 999;
    filter: unset;
    opacity: 0;
}
.process:nth-child(1) .process-step:after {
animation: fill1color 1.5s ease-in-out infinite alternate-reverse;
}
.process:nth-child(2) .process-step:after {
animation: fill1color 1.5s 1s ease-in-out infinite alternate-reverse;
}

@keyframes fill1color {
    0%{
/*           filter: unset;*/
           opacity: 0;
    }
    100%{
/*       filter: hue-rotate(299deg);*/
       opacity: 1;

    }
}
figure.process-icon img {
    width: 70px;
}
.process:nth-child(even) .process-step {
    border-bottom: 0;
    border-top: 3px solid var(--golden);
}
section#featured-services{
    background: linear-gradient(45deg, #a1a0a0e3, #000000c7), url(/public/images/10701724_19187756.jpg);
    background-attachment: fixed;
    background-position: top;
    background-size: cover;
    position: relative;
}
section#featured-services .section-title h3, section#featured-services .section-title h4 {
    color: #ffffffd4;
}
a.becomePas {
    background: var(--dark);
    width: 150px;
    padding: 8px 20px;
    box-sizing: content-box;
    text-align: center;
    border-radius: 8px;
    margin: 10px 0;
    color: #fff;
}

a.becomePas:hover {
    background: var(--golden);
    color: #fff;
}
section#aboutCops {
    position: relative;
}

section#aboutCops:after {
    content: url(/public/images/bgstack.png);
    position: absolute;
    width: 50%;
    height: 100%;
    left: -17%;
    top: 0;
    z-index: 0;
    opacity: 0.5;
}
section#aboutCops .container {
    position: relative;
    z-index: 1;
}
a#draggable{
    animation: whatsaPPicon 1s ease-in-out alternate-reverse infinite;
}

@keyframes whatsaPPicon{
    0%{
    bottom: 20px;
    }
    100%{

        bottom: 40px;
    }
}

/*inner Pages*/
section.noBgImage {
    background: none !important;
}

section.noBgImage .section-title h3,
section.noBgImage .section-title h4
 {
    color: var(--dark) !important;
}
.featured-services .icon-box.abt-div {
    border: 2px solid var(--golden);
}

.featured-services .icon-box.abt-div .icon {
    width: 200px;
    height: 200px;
    background: transparent;
}

.featured-services .icon-box.abt-div .icon img {
    width: 100%;
}

.featured-services .icon-box.abt-div:hover .icon img {
    filter: drop-shadow(2px 4px 6px black);
}
.featured-services .icon-box.abt-div:hover::before{
    display: none;
}
.featured-services .icon-box.abt-div:hover h4.title a,
.featured-services .icon-box.abt-div:hover .description{
    color: #000;
}
.section-title.inner_div_text p {
    margin-top: 20px;
}

.section-title.inner_div_text {
    width: 80%;
    margin: auto;
}

.featured-services .icon-box.abt-div h4.title a {
    text-transform: uppercase;
    font-size: 26px;
    font-weight: 500;
    pointer-events: none;
}
.abt_innersPas p.fst-italic {
    border: 0;
    margin: 0;
    color: #fff;
}

.abt_innersPas ul.topmenuul .login {
    margin: 0 !important;
}   
.abt_innersPas h3.titleSubTextspan {
    border-bottom: 1px solid var(--golden);
    width: 100%;
    padding-bottom: 14px;
    text-transform: uppercase;
    color: #fff;
}

.abt_innersPas {
    padding-right: 38px;
}
section#aboutCops.about.section-bg.bgScretiond:after{
   display: none;
}
div#guessstarts {
    border-radius: 50px;

}

.commonDIvUI {
    background: #ddd;
    text-align: center;
    padding: 50px 20px;
    border-radius: 0 !important;
    /* width: 49.5%; */
    border: 0 !important;
    /* margin: 3px 2px; */
}

.commonDIvUI.radius1 {
    border-top-left-radius: 20px;
    background: var(--dark);
}

.commonDIvUI.radius2 {
    border-top-right-radius: 20px;
    background-color: var(--black);
}

.commonDIvUI.radius3 {
    border-bottom-left-radius: 20px;
    background-color: var(--black);
}

.commonDIvUI.radius4 {
    border-bottom-right-radius: 20px;
    background-color: var(--dark);
    /* border-top: 2px solid var(--golden); */
    border-left: 2px solid var(--golden);
}

.icon-box-item p {
    color: #ffffffab;
}
.icon-box-item h4 a {
    pointer-events: none;
    color: var(--golden);
}

.icon-box-item .icon.iconpors {border: 0;padding: 10px;width: 110px;height: 110px;background: transparent;}

.icon-box-item .icon.iconpors img {
    background: transparent;
    filter: unset;
    border-radius: 0;
    width: 140px;
}
.icon-box-item h4 {
    margin-bottom: 15px;
    padding-top: 15px;
    color: var(--golden);
}

li.dropdown ul.dropdown-menu li:hover {background: var(--golden);color: var(--white);}

li.dropdown ul.dropdown-menu li:hover >  a {
    color: var(--white);
    border-bottom-color: transparent;
}

.navbar .dropdown .dropdown ul.dropdown-menu li:hover a {
    color: var(--white);
}

.navbar .dropdown .dropdown ul.dropdown-menu li:hover {
    background: var(--golden);
}

.navbar .dropdown .dropdown ul.dropdown-menu li {
    border-radius: 0;
}
section#aboutCops.about.section-bg.bgScretiond {
    /* background: url(../img/abouDownbg.jpg); */
    background-attachment: fixed;
}

section#aboutCops.about.section-bg.bgScretiond ul.topmenuul img {
    border: 1px solid #fff;
    border-radius: 10px;
}
.about .content ul.topmenuul {
    padding-top: 20px;
}
.at_table_row a {
    background: #000;
    font-size: 14px;
    padding: 8px 16px;
    border-radius: 0;
    margin: 0;
    display: block;
}
.bgHeadColor {
    background-color: var(--golden) !important;
    text-align: left;
    color: var(--dark)!important;
    font-weight: 600;
    border-bottom: 1px solid #fff !important;
    font-size: 14px;
}

thead.info th, thead.info td:first-child {
    background: var(--dark) !important;
    color: var(--white) !important;
    font-size: 14px;
}

thead.info td {
    font-weight: 500;
}

thead.info tr td:first-child {
    font-weight: 600 !important;
}

.at_table_row.table-responsive td {
    background: #ffffffeb;
    border: 1px solid #ca9a0866;
    font-size: 14px;
    font-weight: 600;
    vertical-align: middle;
    padding: 10px;
}
.at_table_row.table-responsive tfoot tr td:first-child {background: transparent;border: 1px solid transparent;}
.at_table_row.table-responsive tfoot td {padding: 0;}
.at_table_row a:hover {
    background: var(--golden);
    color: var(--black);
}

section.featured-services_tables {
    /* background: url(../img/bgTable.jpg); */
    background-size: 100% 100%;
    background-attachment: fixed;
}
.listFlex{
    color: #fff !important;
    
}
.at_table-container .at_table_row.table-responsive {
    overflow: hidden;
/*    border-radius: 10px;*/
/*    box-shadow: 0px 0px 0px 1px #00000087;*/
/*    padding: -1px;*/
}

.at_table-container .at_table_row.table-responsive table#at_table {
    margin: 0;
}
table#dw_table th {
    font-weight: 400;
    font-size: 14px;
    vertical-align: middle;
}
table#dw_table td {
    vertical-align: middle;
    background: #d6c388b8;
    border: 1px solid #937d7d;
}
section#abopuespPosyewe {
    /* background: linear-gradient(45deg, #f5f3f321, #f5f3f39c), url(../img/abopuesp.jpg); */
    background-size: cover;
    background-attachment: fixed;
}
table#dw_table tr td:first-child {
    background: #fff;
}

div#caluclatorTExt p.fst-italic span {
    font-size: 16px;
    font-weight: 500;
    margin: 10px 0;
    display: flex;
    align-items: center;
    line-height: 20px;
    color: #fff;
}

div#caluclatorTExt h3.titleSubTextspan {
    margin: 0;
    font-size: 20px;
    color: #fff;
}

div#caluclatorTExt p.fst-italic {margin-top: 10px;padding: 15px 0;}


div#caluclatorTExt p.fst-italic span img {
    margin-right: 10px;
}

/*calculator*/
.calculator_tooltip-wrapper label.calculator_label{display: block;color: #fff;font-weight: 500;font-size: 14px;}
option.hiddenInput {
    display: none;
}
.calculator_input {
    width: 100%;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin: 5px 0 10px 0;
    font-size: 14px;
}

.calculator_wrapper_main {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.calculator_tooltip-wrapper {
    width: 48%;
    margin-bottom: 14px;
}

div#noBuySell {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 15px;
    color: var(--white);
    background: var(--black);
    padding: 10px;
    border: 1px solid #dddddd47;
}
div#usd_conversion_result.calculator_tooltip-wrapper {
    width: 100%;
}

div#noBuySell a {
    color: var(--golden);
    font-weight: 600;
}
.calculator {
    padding: 20px;
    background: #131313;
    border-radius: 10px;
    margin: 20px 0;
}

.calculator_btn-wrapper button {border: 0;background: var(--golden);transition: 0.3s;margin: 0px auto;display: block;width: 130px;font-weight: 600;padding: 0 !important;height: 40px;text-align: center;border-radius: 10px;}

.calculator_btn-wrapper {
    width: 100%;
}

.calculator_btn-wrapper button:hover {
    background: var(--white);
    color: var(
    --dark);
    transition: 0.3s;
}
section#calculatorBanner {
    /* background: linear-gradient(45deg, #ffffffde, #efefefcf), url(../img/marketBg.jpg); */
    background-attachment: fixed;
}

section#calculatorBanner .section-title h3, .section-title.inner_div_text p {
    color: var(--dark);
}
.container-calculator {background: #131313;padding: 30px 20px 0px 20px;border-radius: 15px;}

div#noBuySell a:hover {
    color: var(--white);
}
.row.flex-colunbs {
    align-items: center;
}
section#calculatorBanner.calculatorBannersds {
    /* background: linear-gradient(45deg, #f9f9f9cf, #cfcfcff2), url(../img/calculaorBg.jpg); */
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
}
.calculator_tooltip-wrapper.calculator_result-wrapper {
    min-width: 40%;
    padding: 15px;
    text-align: center;
    color: #fff;
    margin: 0px auto 20px auto;
    border-radius: 10px;
    font-weight: 600;
}

.calculator_tooltip-wrapper.calculator_result-wrapper label.calculator_label {
    font-weight: 600;
}
li.dropdown ul:not(.dropdown-menu) li:hover{
    background: var(--golden);
    color: var(--white);
}
li.dropdown ul:not(.dropdown-menu) {
    border-radius: 5px;
    overflow: hidden;
}
section#featured-services.noBgImage.mt-0 {
    padding-bottom: 20px;
}
h3.titleSubTextspan.testatepo {
    font-size: 20px;
    line-height: normal;
    color: #fff;
    font-weight: 500;
    margin-top: 20px;
}

h3.titleSubTextspan.testatepo ~ p.fst-italic {
    padding: 8px 0;
    margin: 10px 0;
    font-size: 16px;
    background: var(--golden);
    padding-left: 12px;
    color: #fff;
    border-left: 4px solid var(
    --white);
}
.tradingview-widget-container {
    background: #443f3f;
    padding: 0;
    border-radius: 0;
    overflow: hidden;
}

.tradingview-widget-container iframe {
    width: 100% !important;
    height: 100% !important;
}
section#aboutCops.foexBGIng{
    background: linear-gradient(279deg, #202124e8, #020202d9), url('../../public/images/innerBanner.jpeg');
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
}
section#aboutCops.foexBGIng:after{
    display: none;
}
.flexBtnforcalcul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
}

.forexCalFlex {
    background: var(--dark);
    width: 48%;
    border-radius: 5px;
    box-shadow: 0px 0px 2px 1px #a7a4a4;
    text-align: center;
    padding: 20px;
    color: #fff;
    font-size: 14px;
}

.forexCalFlex a.becomePas {border: 1px solid #cfa31e85;padding: 6px 20px;margin: 20px auto 0px auto;display: block;}
.flexBtnforcalculListy li i {width: 5px;height: 5px;display: flex;align-items: center;justify-content: center;padding: 12px !important;font-size: 22px !important;color: var(--golden) !important;}

.flexBtnforcalculListy li {
    color: #fff;
    margin-top: 25px !important;
    margin-bottom: 11px !important;
    font-size: 14px;
}

.flexBtnforcalculListy li a {padding: 0px 10px;color: var(--golden);font-weight: 600;}

.flexBtnforcalculListy {
    border-top: 1px solid #ddd;
    margin-top: 20px;
}
table#at_table.postmajorsp tr td:nth-child(1) {
    background: var(--black);
    color: #fff;
}
td.tdhiegt {
    background: var(--golden) !important;
}

.at_table-container .at_table_row.table-responsive table#at_table.postmajorsp {
    vertical-align: middle;
    font-size: 14px;
}

.at_table-container .at_table_row.table-responsive table#at_table.postmajorsp td, .at_table-container .at_table_row.table-responsive table#at_table.postmajorsp th {
    font-size: 13px;
}
section#minorBgstack {
    /* background: linear-gradient(45deg, #00000038, #000000), url(../img/innerBanner.jpg); */
    padding: 20px 0;
    background-attachment: fixed;
}
section#minorBgstack .section-title h3 {
    color: #fff;
}
section.featured-services_tables.notopso {
    padding-top: 20px;
}
section#aboutCops.foexBGIng.metalsBg{
    /* background: linear-gradient(279deg, #000000e0, #000000d9), url(../img/metalsBg.jpg); */
    background-attachment: fixed;
}
section#aboutCops.foexBGIng.oilbg{
    /* background: linear-gradient(279deg, #000000ad, #000000b3), url(../img/oil.jpg); */

    background-attachment: fixed;
}
section#aboutCops.foexBGIng.cryptoBG{
    /* background: linear-gradient(279deg, #000000a3, #060606c9), url(../img/crypto.jpg); */
    background-attachment: fixed;
}
.at_table_row.table-responsive.mt-8 {
    margin-top: 50px;
}
section#minorBgstack.cryptoBGpop {
    /* background: linear-gradient(45deg, #000000bf, #000000a6), url(../img/crypto2.jpg); */
    background-attachment: fixed;
}
p.poseItalicspo {
    font-size: 16px;
    color: var(--dark);
    line-height: 30px;
    border-bottom: 1px solid var(
    --golden);
    padding-bottom: 20px;
    font-weight: 600;
}

section#aboutCops.about.section-bg.bgScretiond.ngChannelPartnet {
    /* background: linear-gradient(98deg, #000000, #0a09099c), url(../img/channelPartner.jpg); */
    background-size: cover;
    background-attachment: fixed;
}
p.poseItalicspo span {
    color: var(--golden);
}
a.btnOPtMenuso {
    background: var(--golden);
    color: #000;
    padding: 10px 15px;
    border-radius: 5px;
    margin: 0px 20px 0px 0px;
    font-weight: 600;
}

a.btnOPtMenuso i {margin-right: 5px;position: relative;top: 1px;font-weight: 500;}

a.btnOPtMenuso:hover {
    background: var(--dark);
    color: #fff;
}
img.img-fluid.imgsao.w100img {
    width: 100%;
}
.box {
    border-radius: 25px 25px 10px 10px;
    box-shadow: 0px 0px 2px 2px #ddd;
    background: #fff;
    overflow: hidden;
    padding: 0;
    width: 31%;
    margin: 10px;
}

.row.no-gutters {
    padding: 10px;
    justify-content: center;
}

.pricinhEader {
    text-align: center;
    background: #000;
    padding: 20px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pricinhEader h3 {
    font-size: 20px;
    margin: 0;
    color: var(--golden);
    font-weight: 600;
}

.box ul {
    list-style: none;
    padding: 20px 10px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
    padding-bottom: 0;
}

.box ul li {
    display: flex;
    align-items: center;
    margin-left: 10px;
    margin-bottom: 15px;
    margin-right: 10px;
    justify-content: center;
    justify-items: center;
    padding-bottom: 6px;
    border-bottom: 1px solid #dddddd36;
    width: 100%;
}

.listFlex {
    padding-left: 15px;
    width: 100%;
    color: var(--dark);
    font-weight: 500;
    font-size: 14px;
}

.listFlex span {
    display: block;
    color: var(--golden);
    font-weight: 600;
    font-size: 14px;
    padding: 3px 0px;
}
a.get-started-btn {
    width: 100%;
    margin: 0px auto;
    text-align: center;
    border-radius: 0;
    padding: 10px;
     font-weight: 800;
    font-size: 18px;
}

.box ul li i {
    min-width: 25px;
    min-height: 25px;
    background: var(--dark);
    color: #fff;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
}

.col-lg-4.box.box1 {
    background: var(--dark);
}

.col-lg-4.box.box1 .pricinhEader {
    background: var(--golden);
    color: #fff;
}

.col-lg-4.box.box1 .pricinhEader h3 {
    color: #fff;
}

.col-lg-4.box.box1 i {
    background: var(--golden);
}

.col-lg-4.box.box1 .listFlex {
    color: #fff;
}

.col-lg-4.box.box1 a.get-started-btn {
    background: var(--golden);
    color: #000;
   
}
.box ul li:last-child {
    border-bottom: 0;
}

/*

.col-lg-4.box.box2 {
    background: var(--golden);
}

.col-lg-4.box.box2 .pricinhEader {
    background: var(--dark);
    color: #fff;
}

.col-lg-4.box.box2 .pricinhEader h3 {
    color: #fff;
}

.col-lg-4.box.box2 i {
    background: var(--dark);
}

.col-lg-4.box.box2 .listFlex {
    color: var(--black);
    font-weight: 600;
}
.col-lg-4.box.box2 .listFlex span {
    color: var(--white);
}
.col-lg-4.box.box2 a.get-started-btn {
    background: var(--dark);
    color: #fff;
   
}

.col-lg-4.box.box2  ul li{
    border-color: #13131352;
}


.col-lg-4.box.box3 {
    background: var(--black);
}

.col-lg-4.box.box3 .pricinhEader {
    background: var(--golden);
    color: #fff;
}

.col-lg-4.box.box3 .pricinhEader h3 {
    color: #fff;
}

.col-lg-4.box.box3 i {
    background: var(--golden);
}

.col-lg-4.box.box3 .listFlex {
    color: var(--white);
}
.col-lg-4.box.box3 .listFlex span {
    color: var(--golden);
}
.col-lg-4.box.box3 a.get-started-btn {
    background: var(--golden);
    color: #000;
   
}

.col-lg-4.box.box3  ul li{
    border-color: #dddddd36;
}*/
section#aboutCops.ngChannelPartnet p.poseItalicspo {
    color: #fff;
}
div#guessstarts .icon-box-item p {
    color: #ffffffab;
    width: 80%;
    margin: 10px auto;
    font-size: 18px;
    line-height: 30px;
}
.col-lg-4.box.box1 a.get-started-btn:hover {
    background: #fff;
}
section#featured-services.brtopsoe {
    padding-top: 0px;
    margin-top: 10px !important;
}

hr.tagspe {
    width: 90%;
    margin: 50px auto;
}
p.mepsoeo {
    margin: 20px 0 10px 0;
}
.contact .info-box p strong {
    display: block;
    color: var(--golden);
    padding-bottom: 8px;
}
.contact .info-box p.mgTextDiv {
    width: 90%;
    margin: 20px auto;
    margin-bottom: 30px;
    position: relative;
    color: #fff;
}
.contactBlsodp {
    display: flex;
    position: relative;
    left: 0;
    bottom: 0;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
}

.contactBlsodp i {
    width: auto;
    padding: 0 !important;
    border: 0 !important;
    font-size:10px  !important;
}

.flexpos a{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 7px;
}
.flexpos {
    display: flex;
    align-items: center;
    width: 48%;
    padding: 0;
    justify-content: center;
    margin: 5px;
    background: #080808b3;
    border: 1px solid #ffffff87;
}

.info-box {
    position: relative;
}
.info-box.ospep {
    padding-bottom: 0;
}
.flexpos a span {
    padding-left: 10px;
    color: #fff;
    font-weight: 600;
}

.flexpos:nth-child(odd) {
    background: #080808b3;
}

.flexpos a i {font-size: 21px !important;background: #272512;padding: 10px !important;}

.social-links.sospde a i {
    background: var(--dark);
    border: 0;
    border-radius: 0;
    padding: 10px;
    margin: 10px;
    color: var(--golden);
    background: #c59c1c2b;
    border: 1px solid #9d9191a1;
}
.social-links.sospde {
    background: #080c0d;
    margin-bottom: 5px;
    border: 1px solid #ffffff80;
    width: 98%;
    margin: 3px auto;
}
.row.flexpowe {
    align-items: center;
}
.flexpos a:hover {
    background: var(--dark);
}
.info-box.ospep {
    background: linear-gradient(45deg, black, transparent), url(/public/images/innerBanner.jpeg);
    background-size: cover;
    background-attachment: fixed;
    background-position: center center;
    padding: 5px;
    padding-top: 30px;
}
.contact .info-box p.mgTextDiv.nomgsj:before {
    content: "";
    position: absolute;
    height: 1px;
    width: 60%;
    background: #ffffff85;
    left: 50%;
    top: -13px;
    transform: translate(-50%, 0);
}
img.img-fluids {
    width: 95%;
}



.posepw {
    padding: 10px 0;
    color: #fff;
}

.posepw p strong {
    color: var(--golden);
    text-decoration: underline;
    text-underline-position: under;
    margin-right: 5px;
}
h3.titleSubTextspan.testatepo.titleChangeps {
    border-bottom: 1px solid #dddddd61;
    padding-bottom: 15px;
    color: var(--golden);
}
.row.flexverticals {
    justify-content: center;
    align-items: center;
}
section#featured-services.masteopsw {
    background: none;
    padding-top: 0;
}

section#featured-services.masteopsw .section-title h3 {
    color: #000;
    padding: 20px;
    padding-bottom: 0;
}
section#featured-services.nomargintops {
    padding-top: 30px;
    border-top: 1px solid #ddd;
}

section#featured-services.masteopsw .description {
    font-weight: 500;
}
#featured-services.aboutCopssdsd3,
#orderTypes.about.section-bg.foexBGIng{
   position: relative; 
}

#featured-services.aboutCopssdsd3,
#orderTypes.about.section-bg.foexBGIng {
    background: url('../../public/images/bannerAbout.jpeg') !important; 
     /* position: absolute;
    width: 50%;
    height: 100%;
    left: -17%;
    top: 0;
    z-index: 0;
    opacity: 0.5; */
    background-size: cover !important;
    background-position: center !important;
    background-attachment: fixed !important;
}
#orderTypes.about.section-bg.foexBGIng img.img-fluids {
    position: relative;
    z-index: 99;
}

#orderTypes.about.section-bg.foexBGIng .content p {color: #000; font-weight: 600}

#orderTypes.about.section-bg.foexBGIng h3.titleSubTextspan.testatepo.titleChangeps {
    border-color: #000;
}

#orderTypes.about.section-bg.foexBGIng {
    padding: 30px;
}
.row.flexverticals.mb-bop {
    margin-bottom: 50px;
}
.posepw.mtospeos p {
    margin-bottom: 30px;
}
.psoeipeowews .section-title.inner_div_text p {
    position: relative;
    z-index: 1;
}
div#tablesopao {
    position: relative;
    z-index: 9;
}
section#majorecepsi {
    background: linear-gradient(289deg, #10010199, #0e0d0d), url('../../public/images/bannerAbout.jpeg');
    background-size: cover;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
}

section#majorecepsi .section-title h3 {
    color: #fff;
}

section#majorecepsi h4.title {font-size: 24px;padding-bottom: 10px;margin-top: 0;}

section#majorecepsi .section-title h3 {
    color: #fff;
}

section#majorecepsi h4.title {font-size: 24px;padding-bottom: 10px;margin-top: 0;}


section#majorecepsi .icon-box:hover::before {
    background: transparent;
}

section#majorecepsi .icon-box:hover h4.title a {
    color: #000 !important;
}
section#majorecepsi h4.title {
    border-bottom: 1px solid #0000005e;
    width: 80%;
    margin: 10px auto;
}
section#aboutCopseree .posepw p {
    color: #000;
    font-weight: 600;
}
section#aboutCopseree h3.titleSubTextspan.testatepo.titleChangeps{
      border-bottom: 1px solid #0f0d0d61;  
}
section#majorecepsi h4.title.fullwo {
    width: 100%;
    font-size: 22px;
}
ul.dropdown-menu li .active {
    color: var(--golden);
}
label.error, .errordiv {
    color: red;
    font-size: 14px;
    background: #fff;
    padding: 0px 10px;
    display: inline-block;
}
button.close {
    border: 0;
    background: #0f0c0c14;
    font-size: 20px;
    padding: 0px 10px;
    position: absolute;
    right: 8px;
    top: 15px;
}
.section-title h3, .col-lg-8.pt-4.pt-lg-0.padding-leo.content{
    position: relative;
    z-index: 1;
}
.row.flexverticals.mb-bop.nomgst {
    margin-bottom: 0;
}
section#aboutCopseree.about.section-bg.foexBGIng {
    background: #fff;
}
.mobileNavigatuion{
    display: none;
}



@media screen and (max-width: 768px) {
    section#topbar {
        display: none !important;
    }
   li.mobileNavigatuion {
    display: flex;
    padding-left: 0;
}

li.mobileNavigatuion a {padding-left: 15px;}

li.mobileNavigatuion a i {
   margin: 0 !important;
    position: static;
    margin-right: 10px !important;
}
.navbar-mobile>ul>li {
    padding-right: 15px;
}
.navbar a i {
    border: 1px solid #ddd;
    padding: 6px;
    position: absolute;
    right: 0;
}

.navbar-mobile .dropdown ul {
    margin: 0;
    box-shadow: none;
    background: #dddddd70;
    padding: 0;
    width: 100%;
    margin-left: 5px;
}
.navbar .dropdown .dropdown ul.dropdown-menu li a {
    color: #000 !important;
}
#header .logo {
    margin: 0;
}
#header .logo {
    margin: 0;
}

.sliderMaineLeft, .sliderMaineRight {
    width: 100%;
    text-align: center;
}

.sliderMain {
    flex-wrap: wrap;
}

ul.listPLesd {
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

ul.listPLesd li {
    width: 100%;
    margin: 5px;
    display: block;
    clear: both;
}
.section-title h3 {
    line-height: 45px;
    font-size: 28px;
}

.section-title h3 span {
    display: inline-flex;
    margin: 10px 0px;
}
.section-title h3 {
    line-height: 45px;
    font-size: 28px;
}

.section-title h3 span {
    display: inline-flex;
    margin: 10px 0px;
}
section#aboutUs .counts .col-lg-2.col-md-6 {
    margin-top: 15px !important;
}
.featured-services .description {
    min-height: auto;
}
.about .content h3 {
    font-size: 26px;
    line-height: 36px;
}
.padding-leo.content {
    text-align: center;
    padding: 10px;
}

a.becomePas {
    margin: 20px auto;
}
.container.FooderPas {
    padding-bottom: 60px;
}
.section-title.inner_div_text {
    width: 100%;
}
p.fst-italic {
    font-size: 18px;
    line-height: 28px;
    font-weight: 500;
}
.commonDIvUI {
    width: 95%;
    margin: 10px auto;
}
i.bi.bi-list.mobile-nav-toggle {
    background: #fff;
    padding: 6px 8px;
    color: #000;
    border: 1px solid #ca9a085c;
}
.box {
    width: 100%;
}
.at_table_row.table-responsive {
    width: 100%;
    overflow: scroll !important;
}
table#dw_table {
    width: 100%;
}
h3.titleSubTextspan.testatepo ~ p.fst-italic {
    border: 0;
    padding: 10px 0;
}
.flexBtnforcalcul {
    flex-wrap: wrap;
}
.forexCalFlex {
    width: 100%;
    margin-bottom: 20px;
}
.about .content ul li a {
    display: inline;
    padding-left: 0;
}

.about .content ul li {
    flex-wrap: wrap;
    padding-left: 40px;
    text-align: left;
    position: relative;
}

.about .content ul i {
    position: absolute;
    left: 0px;
}
section#aboutCops.foexBGIng {
    padding-bottom: 0px;
}
.at_table_row.table-responsive td {
    min-width: 115px;
}
section#aboutCops.foexBGIng.cryptoBG {
    padding: 0;
    padding-bottom: 20px;
}
section#aboutCops.about.section-bg.bgScretiond.ngChannelPartnet {
    padding-top: 10px;
}
section {
    padding: 30px 0;
}
section#aboutCops.about.section-bg.bgScretiond br {
    display: none;
}

.section-title {
    padding-bottom: 0;
}

.flebtnOisu {
    flex-wrap: wrap;
    display: flex;
}

a.btnOPtMenuso {
    width: 60%;
    margin: 00px auto 20px auto;
}
.featured-services .icon-box {
    border: 1px solid var(--golden);
}
.featured-services .icon-box {
    border: 1px solid var(--golden);
}
section#majorecepsi.featured-services.masteopsw.aboutCopssdsd3 .section-title {
    margin-bottom: 30px;
}

section#aboutCopseree.about.section-bg.foexBGIng .row.flexverticals.mb-bop:nth-child(even) {
    flex-direction: column-reverse;
}

section#aboutCopseree.about.section-bg.foexBGIng .row.flexverticals.mb-bop {
    margin-bottom: 0;
}
.calculator_tooltip-wrapper {
    width: 100%;
    text-align: left;
}
div#caluclatorTExt p.fst-italic span {
    text-align: left;
    line-height: 24px;
    margin-bottom: 15px;
}
.flexpos {
    width: 100%;
}
.contact .info-box i {
    border: navajowhite;
}
.flexpos a {
    justify-content: flex-start;
}
/* .info-box.ospep {
    /* background: linear-gradient(45deg, black, #161313ba), url(../img/conactDB.jpg); */
/* } */
.abt_innersPas ul.topmenuul .login {
    padding: 0;
}

.about .content ul.topmenuul {
    justify-content: center;
    display: flex;
    align-items: center;
}

section#aboutCops.about.section-bg.bgScretiond ul.topmenuul img {
    width: 90%;
    margin: 0px auto;
    display: block;
}
}